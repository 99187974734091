import type {AppLoadContext} from '@shopify/remix-oxygen';
import {type Variables} from 'graphql-request';

import {client} from '~/lib/graphql-client';

export const graphQLService = (context: AppLoadContext) => {
  const {env} = context;
  const {DELIVERED_COLD_API, CF_ACCESS_CLIENT_ID, CF_ACCESS_CLIENT_SECRET} =
    env;

  const cloudflareAccess =
    CF_ACCESS_CLIENT_ID && CF_ACCESS_CLIENT_SECRET
      ? {
          clientId: CF_ACCESS_CLIENT_ID,
          clientSecret: CF_ACCESS_CLIENT_SECRET,
        }
      : undefined;

  const getGraphQL = <T>(query: string, variables?: Variables | undefined) =>
    client(DELIVERED_COLD_API, cloudflareAccess).request<T>(query, variables);

  return {getGraphQL};
};
