import {GraphQLClient} from 'graphql-request';

export type CloudflareAccess = {
  clientId: string;
  clientSecret: string;
};

export const client = (path: string, cloudflareAccess?: CloudflareAccess) =>
  new GraphQLClient(path, {
    headers: cloudflareAccess
      ? () => ({
          'CF-Access-Client-Id': cloudflareAccess.clientId,
          'CF-Access-Client-Secret': cloudflareAccess.clientSecret,
        })
      : undefined,
    fetch,
  });
